.k-tabstrip {
  .k-tabstrip-items {
    border-bottom: 0;
    background-color: white;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0 auto;
    z-index:2;
    width: 100%;
    padding-top: 20px;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  
    .k-item {
      color: $dark_grey_100;
      transition: all 0.3s;
      border: none !important;
      border-color: transparent !important;
      border-width: 0;
  
      .k-link {
        margin-bottom: 5px;
      }
  
      &::after {
        content: "";
        height: 2px;
        width: 100%;
        background-color: transparent;
        position: absolute;
        bottom: 0;
        left:0;
      }
  
      &:focus {
        border: none !important;
        border-color: transparent !important;
        border-width: 0;
      }
  
      &:hover {
        border: none !important;
        border-color: transparent !important;
        border-width: 0;
        color: $dark_grey_500;
  
        &::after {
          background-color: $dark_grey_500;
        }
      }
    }
  
    .k-state-active {
      color: $dark_grey_500;
      border: none;
      position: relative;
  
      &::after {
        background-color: $dark_grey_500;
      }
    }
  }

  .k-content {
    border: none !important;
  }
}
