@import "../../../main.scss";

.menu-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }

  .loading-spinner {
    display: none !important;

    @media screen and (min-width: $lg) {
      display: flex !important;
    }
  }

  @media screen and (max-width: $lg) {
    &.responsive-menu-expanded {
      .inner-content {
        display: flex;
        background-color: $blue_150;
        height: 100vh !important;
        width: 100vw !important;
        overflow-y: scroll;
        padding-top: 60px !important;
      }
    }
  }
  
  @media screen and (min-width: $lg) {
    position: relative;
    width: 20%;
    height: 100vh;
    overflow-y: scroll;
  }

  @media screen and (min-width: $xxl) {
    width: 15%;
  }

  .responsive-menu-trigger {
    display: flex;
    position: absolute;
    background-color: $blue_150;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 2;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;

    @media screen and (min-width: $lg) {
      display: none;
    }
  }

  .inner-content {
    display: none;

    @media screen and (min-width: $lg) {
      display: flex;
    }
  }

  .member-lookup {
    position: relative;

    .icon {
      position: absolute;
      z-index: 2;
      left: 25px;
      top:0;
      bottom:0;
      margin: auto;
    }

    .k-input-solid {
      background-color: #F1F5F9 !important;
      border: none !important;
      z-index: 0;

      input {
        font-size: 14px;
        padding-left: 35px;
        height: 40px;

        &::placeholder {
          font-size: 14px;
        }
      }
    }
  } 
  
  .menu-item {
    margin: 7px 0;
    cursor: pointer;
    border-radius: 5px;
    background-color: transparent;
    transition: all 0.3s;
    width: 90%;
    margin: 0 auto 5px auto;
    
    p {
      font-size: 13px;
      color: #0F172A;
    }

    &.support {
      color: #3B82F6 !important;
    }

    &:hover, &.selected {
      background-color: #E9EEF4;
    }
  }

  .new-club-button {
    font-size: 13px;
    color: #0F172A;
    cursor: pointer;

    transition: all 0.3s;

    &:hover {
      background-color: #E5EAF0 !important;
    }
  }

  .menu-item-title {
    margin: 15px 0 15px 1rem !important;

    p {
      font-size: 14px;
      font-weight: 400;
      color: rgba(#6E6F71, 0.7);
    }
  }
  
  .divider {
    width: 100%;
    height: 2px;
    background-color: rgba(#E9E9E9, 0.5);
    margin: 20px 0;
  }

  .client-logo {
    width: 50%;
    height: 0;
    padding-top: 25%; 
    background-position: left center; 
    background-size: contain; 
    background-repeat: no-repeat;
    
    @media screen and (min-width: $lg) {
      width: 70%; 
    }
  }

  .simple-logo {
    background-image: url('../../../images/smart-simple-logo.png');
  }

  .logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 60%;
    height: 20px;
    margin: 0 auto;
  }
}
