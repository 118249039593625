@media print {

  @page {
    size: landscape;
  }

  header,
  .secondary-menu-wrapper,
  .menu-wrapper {
    display: none;
    visibility: hidden;
  }

  .container {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    padding: 0;    
    display: block;    
  }

  .app .app-content {
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: auto;
    height: fit-content;
  }

  .row {
    break-inside: avoid;
  }
}