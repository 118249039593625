* {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.text-green-500 {
  color: $green_500;
}

.text-dark-grey-200 {
  color: $dark_grey_200;
}

.text-dark-grey-100 {
  color: $dark_grey_100;
}

.text-red-500 {
  color: $red_500;
}

.font-size-90 {
  font-size: 90%;
}

.font-size-85 {
  font-size: 85%;
}

.text-align-right {
    text-align: right;
}