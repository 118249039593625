$dark_grey_500: #1A1B20;
$dark_grey_400: #202127;
$dark_grey_300: #363842;
$dark_grey_200: #5F5F63;
$dark_grey_100: #BDC1C4;

$light_500: #F2F3F3;


$green_600: #0B5B48;
$green_500: #118C6E;
$green_400: #118C6E;
$green_300: #15B089;
$green_200: #16B88F;
$green_100: #1AD9A9;



$blue_100: #FAFDFF;
$blue_150: #F8FAFC;
$blue_200: #EDF2F4;
$blue_400: #5ED5FF;
$blue_500: #00A7E1;
$blue_600: #00678B;


$orange_500: #FF7F11;

$red_500: #FF3105;

$box-shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
$box-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$box-shadow-md: 0 5px 10px -3px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.05);

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;