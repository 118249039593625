@import "./_variables";

.secondary-nav-screen-wrapper {
  margin: 0 auto;

  .create-button {
    margin-top: 30px;
  }

  .collapse-strip-trigger {
    cursor: pointer;
    display:none;

    @media screen and (min-width: $xl) {
      display: block;
    }
  }

  .k-tabstrip-items {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .tab-wrapper {
    height: 100%;

    
    .k-item {
      border-bottom: 2px solid transparent;
      border-radius: 5px;
      margin-bottom: 5px;
      
      .k-link {
        font-size: 14px !important;
        margin: 0 !important;
      }
      
      &::after {
        content: none!important;
      }
      
      &.k-active, &:hover {
        background-color: #EAEFF4;
      }
    }
    
    .k-tabstrip-items {
      padding-top: 20px !important;
    }
    
    .k-tabstrip-items-wrapper {
      z-index:0 !important;
      margin-bottom: 0 !important;
      background-color: transparent !important;
      padding-right: 20px;
      border: none !important;
      transition: all 0.5s;
      transition-delay: 0.1s;
      
      @media screen and (min-width: $xl) {
        border-right: 1px solid #e9e9e9 !important;
        overflow: auto;
        width: 15%;
        height: 100vh;
      }

      li {
        opacity: 1;
        transition: opacity 1s ease-in;
      }
    }
    
    .k-tabstrip {
      &.collapse-strip {
        .k-tabstrip-items-wrapper {
          width: 50px !important;
          overflow: hidden !important;
          
          li {
            opacity: 0 !important;
            transition: opacity 0.1s ease-out;
          }
        }
      }
    }
    
    .k-content {
      border: none !important;
      background-color: transparent;
      width: 100%;
      padding: 0 5px;
      
      @media screen and (min-width: $xl) {
        padding: 0 40px;
        width: 85%;
      }
    }
  }

  .grid-list {
    margin-top: 60px;
  }
}
