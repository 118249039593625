@import "./styles/_variables.scss";

@import "./styles/_typography.scss";
@import "./styles/_icons.scss";
@import "./styles/_backgrounds.scss";
@import "./styles/_box-shadow.scss";
@import "./styles/_border.scss";
@import "./styles/_tabs.scss";
@import "./styles/_buttons.scss";
@import "./styles/_tabstrip.scss";
@import "./styles/_form.scss";
@import "./styles/_secondary-nav.scss";

.app {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  background-color: $blue_150;
  
  .app-content {
    padding: 80px 24px 24px 24px;
    width: 100%;
    background-color: white;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    z-index:0;

    &:has(> .dashboard) {
      @media screen and (max-width: $lg) {
        overflow-y: auto;
      }
    }
    
    @media screen and (min-width: $lg) {
      box-shadow: -25px -20px 19px rgba(0, 0, 0, 0.01), -11px -9px 14px rgba(0, 0, 0, 0.02), -3px -2px 8px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02);
      padding: 24px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      width: 80%;
    }
  
    @media screen and (min-width: $xxl) {
      width: 85%;
    }
  }
}

.k-animation-container {
  z-index: 10003 !important;
}

.k-dialog-wrapper {
  z-index: 10003 !important; 
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

@import "./styles/_print.scss";