.icon {
  &.icon-40 {
    background-size: 37px 37px;
    background-position: center;
    background-repeat: no-repeat;

    height: 40px;
    width: 40px;
  }

  &.icon-25 {
    background-size: 23px 23px;
    background-position: center;
    background-repeat: no-repeat;

    height: 25px;
    width: 25px;
  }

  &.icon-60 {
    background-size: 57px 57px;
    background-position: center;
    background-repeat: no-repeat;

    height: 60px;
    width: 60px;
  }

  &.icon-20 {
    background-size: 18px 18px;
    background-position: center;
    background-repeat: no-repeat;

    height: 20px;
    width: 20px;
  }

  &.double-arrow-right {
    background-image: url(../images/icons/double-arrow-right-icon.svg);
  }

  &.double-arrow-left {
    background-image: url(../images/icons/double-arrow-left-icon.svg);
  }

  &.burger-menu {
    background-image: url(../images/icons/burger-menu-icon.svg);
  }

  &.send-message {
    background-image: url(../images/icons/send-message-icon.svg);
  }

  &.new-plus {
    background-image: url(../images/icons/new-plus-icon.svg);
  }

  &.activity {
    background-image: url(../images/icons/activity-icon.svg);
  }

  &.order-processing {
    background-image: url(../images/icons/order-processing-icon.svg);
  }

  &.club-schedule {
    background-image: url(../images/icons/club-schedule-icon.svg);
  }

  &.new-dashboard {
    background-image: url(../images/icons/new-dashboard-icon.svg);
  }

  &.my-tasks {
    background-image: url(../images/icons/my-tasks-icon.svg);
  }

  &.member-search {
    background-image: url(../images/icons/member-search-icon.svg);
  }

  &.your-clubs {
    background-image: url(../images/icons/your-clubs-icon.svg);
  }

  &.new-messaging {
    background-image: url(../images/icons/new-messaging-icon.svg);
  }

  &.new-reporting {
    background-image: url(../images/icons/new-reporting-icon.svg);
  }
  
  &.system-settings {
    background-image: url(../images/icons/system-settings-icon.svg);
  }

  &.sidebar-search {
    background-image: url(../images/icons/sidebar-search-icon.svg);
  }

  &.logout {
    background-image: url(../images/icons/logout-icon.svg);
  }

  &.support {
    background-image: url(../images/icons/support-icon.svg);
  }

  &.dashboard {
    background-image: url(../images/icons/dashboard-icon.svg);
  }

  &.search-white {
    background-image: url(../images/icons/search-icon-white.svg);
  }

  &.disabled {
    background-image: url(../images/icons/disabled-icon.svg);
  }

  &.tasks {
    background-image: url(../images/icons/list-outline.svg);
  }

  &.pending {
    background-image: url(../images/icons/pending-icon.svg);
  }

  &.error {
    background-image: url(../images/icons/error-icon.svg);
  }

  &.getting-started {
    background-image: url(../images/icons/getting-started-icon.svg);
  }

  &.help-club-setup {
    background-image: url(../images/icons/club-setup-icon.svg);
  }

  &.home {
    background-image: url(../images/icons/home-icon.svg);
  }

  &.members {
    background-image: url(../images/icons/members-icon.svg);
  }

  &.help-tasks {
    background-image: url(../images/icons/help-tasks-icon.svg);
  }

  &.marketing {
    background-image: url(../images/icons/marketing-icon.svg);
  }

  &.help-messaging {
    background-image: url(../images/icons/help-messaging-icon.svg);
  }

  &.help-reporting {
    background-image: url(../images/icons/help-reporting-icon.svg);
  }

  &.faq {
    background-image: url(../images/icons/faq-icon.svg);
  }

  &.prospect {
    background-image: url(../images/icons/prospect-icon.svg);
  }

  &.not-interested {
    background-image: url(../images/icons/not-interested-icon.svg);
  }

  &.tick {
    background-image: url(../images/icons/tick-icon.svg);
  }

  &.edit {
    background-image: url(../images/icons/edit-icon.svg);
  }


  &.member {
    background-image: url(../images/icons/member-icon.svg);
  }

  &.calendar {
    background-image: url(../images/icons/calendar-icon.svg);
  }

  &.delete {
    background-image: url(../images/icons/delete-icon.svg);
  }

  &.info {
    background-image: url(../images/icons/info-icon.svg);
  }

  &.list {
    background-image: url(../images/icons/list-icon.svg);
  }

  &.plus {
    background-image: url(../images/icons/plus-icon.svg);
  }

  &.clubadmin {
    background-image: url(../images/icons/clubadmin-icon.svg);
  }

  &.messaging {
    background-image: url(../images/icons/messaging-icon.svg);
  }

  &.tasks {
    background-image: url(../images/icons/tasks-icon.svg);
  }

  &.reporting {
    background-image: url(../images/icons/reporting-icon.svg);
  }

  &.setup {
    background-image: url(../images/icons/setup-icon.svg);
  }

  &.cross {
    background-image: url(../images/icons/cross-icon.svg);
  }

  &.chevron-right {
    background-image: url(../images/icons/chevron-right-icon.svg);
  }

  &.chevron-left {
    background-image: url(../images/icons/chevron-left-icon.svg);
  }

  &.arrow-left {
    background-image: url(../images/icons/arrow-left-icon.svg);
  }

  &.arrow-right-white {
    background-image: url(../images/icons/arrow-right-white-icon.svg);
  }

  &.currency:before{
      content: "\00a3";
  }
}