.bg-green-500 {
  background-color: $green_500 !important;
}

.bg-blue-600 {
  background-color: $blue_600;
}

.bg-blue-400 {
  background-color: $blue_400;
}

.bg-green-500-5 {
  background-color: rgba($green_500, 0.2);
}

.bg-dark-grey-100 {
  background-color: $dark_grey_100;
}

.bg-orange-500 {
  background-color: $orange_500;
}

.bg-orange-500-5 {
  background-color: rgba($orange_500, 0.2);
}

.bg-blue-500 {
  background-color: $blue_500;
}

.bg-blue-500-5 {
  background-color: rgba($blue_500, 0.2);
}

.bg-dark-grey-500 {
  background-color: $dark_grey_500;
}

.bg-dark-grey-400 {
  background-color: $dark_grey_400;
}

.bg-dark-grey-300 {
  background-color: $dark_grey_300;
}