.input-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    float: left;
    margin-bottom: 15px;
    margin-left: 3px;
    margin-top: 0 !important;

    label {
        margin-bottom: 0;
        font-size: 14px;
    }

    input {
        width: 100%;
    }

    .k-numerictextbox {
        width: 100%;
        height: 43px;

        .k-numeric-wrap {
            border: 1px solid #e9e9e9;
        }
    }

    .k-textbox, .k-textarea, .k-input {
        height: 43px;
        border: 1px solid #e9e9e9;
    }

    .k-textbox {
        height: 43px;
    }

    .k-textarea {
        height: 86px;
    }

    .k-dropdown {
        width: 100%;

        .k-dropdown-wrap {
            background-color: white;
            border: 1px solid #e9e9e9;
            height: 43px;
        }
    }

    .k-slider-horizontal{
        width: 100%;
        padding: 0 10px 0 10px;
    }
}
