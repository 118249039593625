.primary-button {
  background-color: $green_500 !important;
  color: white !important;
  border: none !important;
}

.delete-button {
  
  border: none !important;
  background-color: #FEE2E2 !important;
  color: #EF4444 !important;

  &:hover {
    background-color: darken(#FEE2E2, 10%);
  }
}