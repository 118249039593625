
.form-tabs {
  border: none !important;

  .k-tabstrip-items, .k-item {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }

  .k-content {
    border: none !important;
    padding: 25px !important;
  }

  .k-tabstrip-items {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;

    .k-item {
      color: $dark-grey-200;
      padding: 5px 15px;
      position: relative;
      text-align: center;

      &::after {
        content: " ";
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -1px;
        left: 0;
        transition: all 0.3s;
        background-color: transparent;
        z-index:2;
      }
      
      &.k-active, &:hover {
        color: $dark_grey_500;

        &::after {
          background-color: $dark-grey-500;
        }
      }
    }
  }
}