.box-shadow-md {
  box-shadow: $box-shadow-md;
}

.box-shadow-lg {
  box-shadow: $box-shadow-lg;
}

.box-shadow-xl {
  box-shadow: $box-shadow-xl;
}